<template>
  <ul class="menu-nav">
    <!--    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
          <router-link to="/offer" class="menu-link btn-secondary">

          </router-link>
        </li>-->
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
};
</script>
