<template>
  <div>
    <div class="topbar">
      <Auth />
    </div>
  </div>
</template>

<script>
import Auth from "@/view/layout/header/Auth";
export default {
  name: "Topbar",
  components: {
    Auth,
  },
};
</script>
