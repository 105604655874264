var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      !_vm.currentWallet ||
        Object.keys(_vm.currentWallet).length === 0 ||
        _vm.currentWallet.constructor === Object
    )?_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"m-5",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Connect ")])]}}],null,false,2690245059),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Choose your wallet provider ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"m-2",attrs:{"color":"primary"},on:{"click":_vm.connect}},[_vm._v(" Metamask / TrustWallet ")])],1),_c('v-card-text',[_vm._v(" Or set manually "),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Wallet address","required":""},model:{value:(_vm.customWallet),callback:function ($$v) {_vm.customWallet=$$v},expression:"customWallet"}}),_c('v-btn',{staticClass:"pl-4",attrs:{"color":"success"},on:{"click":_vm.manualWallet}},[_vm._v(" Submit ")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentWallet &&
        Object.keys(_vm.currentWallet).length !== 0 &&
        _vm.currentWallet.constructor !== Object
    )?_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"m-5",attrs:{"outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.shortWallet)+" ")])]}}],null,false,1209396439),model:{value:(_vm.dialogLogout),callback:function ($$v) {_vm.dialogLogout=$$v},expression:"dialogLogout"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Your wallet ")]),_c('v-card-text',{staticClass:"font-size-lg mt-5 pb-0"},[_c('p',{staticClass:"font-size-lg"},[_vm._v(" "+_vm._s(_vm.currentWallet)+" ")])]),_c('v-card-actions',{staticClass:"pb-5 ml-2"},[_c('a',{staticClass:"btn btn-primary mr-3",attrs:{"href":'https://bscscan.com/address/' + _vm.currentWallet,"target":"_blank"}},[_vm._v("View on BscScan")]),_c('b-button',{attrs:{"variant":"info"},on:{"click":_vm.logout}},[_vm._v("Logout")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }