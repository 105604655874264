<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500"
      v-if="
        !currentWallet ||
          Object.keys(currentWallet).length === 0 ||
          currentWallet.constructor === Object
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="m-5" v-bind="attrs" v-on="on">
          Connect
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Choose your wallet provider
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" class="m-2" @click="connect">
            Metamask / TrustWallet
          </v-btn>
        </v-card-actions>
        <v-card-text>
          Or set manually
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="Wallet address"
              v-model="customWallet"
              required
            ></v-text-field>
            <v-btn color="success" class="pl-4" @click="manualWallet">
              Submit
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogLogout"
      max-width="500"
      v-if="
        currentWallet &&
          Object.keys(currentWallet).length !== 0 &&
          currentWallet.constructor !== Object
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn outlined color="primary" class="m-5" v-bind="attrs" v-on="on">
          {{ shortWallet }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Your wallet
        </v-card-title>
        <v-card-text class="font-size-lg mt-5 pb-0">
          <p class="font-size-lg">
            {{ currentWallet }}
          </p>
        </v-card-text>
        <v-card-actions class="pb-5 ml-2">
          <a
            class="btn btn-primary mr-3"
            :href="'https://bscscan.com/address/' + currentWallet"
            target="_blank"
            >View on BscScan</a
          >
          <b-button variant="info" @click="logout">Logout</b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_WALLET, LOGOUT } from "@/core/services/store/auth.module";
import Web3service from "@/core/services/web3.service";
const { ethereum } = window;
export default {
  name: "Auth",
  computed: {
    ...mapGetters(["currentWallet"]),
    shortWallet() {
      var start = this.currentWallet.substring(0, 4);
      var end = this.currentWallet.substring(this.currentWallet.length - 4);
      return start + "...." + end;
    },
  },
  data() {
    return {
      valid: true,
      dialog: false,
      dialogLogout: false,
      customWallet: null,
    };
  },
  methods: {
    connect() {
      ethereum
        .request({ method: "eth_requestAccounts" })
        .then(accounts => {
          this.$store.commit(SET_WALLET, accounts[0]);
        })
        .catch(err => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log("Please connect to MetaMask.");
          } else {
            console.error(err);
            alert(err);
          }
        });

      this.dialog = false;
    },
    logout() {
      this.dialog = false;
      this.dialogLogout = false;
      this.customWallet = null;
      this.$store.dispatch(LOGOUT);
    },
    async manualWallet() {
      let valid = await Web3service.checkAddress(this.customWallet);
      if (valid) {
        this.$store.commit(SET_WALLET, this.customWallet);
      }
    },
  },
};
</script>
