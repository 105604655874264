<template>
  <div class="aside aside-left d-flex flex-column" id="kt_aside" ref="kt_aside">
    <!--begin::Brand-->
    <KTBrand></KTBrand>
    <!--end::Brand-->
    <!--begin::Nav Wrapper-->
    <div
      class="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-7"
    >
      <!--begin::Nav-->
      <ul class="nav flex-column">
        <!--begin::Item-->
        <li class="nav-item mb-5">
          <router-link
            to="/home"
            active-class="active"
            class="nav-link btn btn-icon btn-clean btn-icon-white btn-lg"
          >
            <i class="flaticon2-rocket icon-lg"></i>
          </router-link>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <!-- <li class="nav-item mb-5">
          <router-link
            to="/tracker"
            active-class="active"
            class="nav-link btn btn-icon btn-clean btn-icon-white btn-lg"
          >
            <i class="flaticon2-rocket icon-lg"></i>
          </router-link>
        </li> -->
        <!--end::Item-->

        <!--begin::Item-->
        <li class="nav-item mb-5" v-b-tooltip.hover.right="'Charts soon!'">
          <router-link
            to="/charts"
            tag="button"
            :disabled="true"
            active-class="active"
            class="nav-link btn btn-icon btn-clean btn-icon-white btn-lg"
          >
            <i class="flaticon2-medical-records-1 icon-lg"></i>
          </router-link>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li
          class="nav-item mb-5"
          v-b-tooltip.hover.right="'Scam checker soon!'"
        >
          <router-link
            to="/checker"
            tag="button"
            :disabled="true"
            active-class="active"
            class="nav-link btn btn-icon btn-clean btn-icon-white btn-lg"
          >
            <i class="flaticon2-warning icon-lg"></i>
          </router-link>
        </li>
        <!--end::Item-->
      </ul>
      <!--end::Nav-->
    </div>
    <!--end::Nav Wrapper-->
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import KTBrand from "@/view/layout/brand/Brand";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";

export default {
  name: "Aside",
  components: {
    KTBrand,
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);
    });
  },
};
</script>
